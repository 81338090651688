import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import GarageDoor from "./GarageDoor";
import Index from "./Index";

function App() {
  return (
    <main>
      <Router>
        <Switch>
          <Route path="/" exact component={() => <Index />} />
          <Route path="/main" exact component={() => <Index />} />
          <Route path="/garagedoor" exact component={() => <GarageDoor />} />
        </Switch>
      </Router>
    </main>
  );
}

export default App;
