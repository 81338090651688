class GaragedoorClient {
  authState: any

  constructor(authState) {
    this.authState = authState
  }

  status(): any {
    return fetch(`/api/garagedoor`, {
      headers: {
        accept: "application/json",
        authorization: "Bearer " + this.authState.idToken
      }
    })
      .then(this.checkStatus)
      .then(this.parseJSON);
  }

  toggle() {
    return fetch(`/api/garagedoor/toggle`, {
      method: 'POST',
      headers: {
        accept: "application/json",
        authorization: "Bearer " + this.authState.idToken
      }
    })
      .then(this.checkStatus);
  }

  checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    const error = new Error(`HTTP Error ${response.statusText}`);
    console.log(error); // eslint-disable-line no-console
    throw error;
  }

  parseJSON(response): object {
    return response.json();
  }
}

export default GaragedoorClient;
