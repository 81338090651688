import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  createStyles,
  makeStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import App from './pages/App';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import {AppBar, Button, Toolbar, Typography} from "@material-ui/core";
import {BrowserRouter as Router, Route} from "react-router-dom";
import config from './config';
import {LoginCallback, Security, useOktaAuth} from '@okta/okta-react';


const useStyles = makeStyles((theme) => createStyles({
  icon: {
    marginRight: theme.spacing(2),
  },
  top: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}));

function Login() {
  const { authState, authService } = useOktaAuth();
  if (authState.isPending) {
    return <div>processing login request...</div>;
  }

  authService.login();
  return (
    <div>logging in...</div>
  );
}

function Layout() {
  const classes = useStyles();
  const { authService } = useOktaAuth();

  const logout = async () => {
    // Redirect to '/' after logout
    authService.logout('/main');
  }

  return  <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <div className={classes.top}>
        <AppBar position="relative">
          <Toolbar>
            <ArtTrackIcon className={classes.icon} />
            <Typography variant="h6" color="inherit" className={classes.title} noWrap>
                PS13 Control Center
            </Typography>
            <Button onClick={() => { logout().catch((e) => {alert('Failed: ' + e)}); }} color="inherit">Logout</Button>
          </Toolbar>
        </AppBar>
      </div>
    <App />
  </ThemeProvider>

}

function Root() {
  const { authState } = useOktaAuth();

  if (authState.isAuthenticated) {
    return <Layout />;
  }
  return (
    <Router>
      <Route path="/auth/callback" component={LoginCallback} />
      <Route path="/" component={Login} />
    </Router>
  );
}

ReactDOM.render(
  <Security {...config.oidc}>
    <Root />
  </Security>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
