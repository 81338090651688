const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '0oap5vdnnOnurZKVC4x6';
const ISSUER = process.env.REACT_APP_ISSUER || 'https://dev-441300.okta.com/oauth2/default';
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL || 'http://localhost:3000/auth/callback';

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URL,
    scopes: ['openid', 'profile', 'email', 'groups'],
    pkce: true,
    disableHttpsCheck: false,
  },
};
