import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import GaragedoorClient from '../clients/garagedoor';
import {Box, Button} from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import PanToolIcon from '@material-ui/icons/PanTool';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import HelpIcon from '@material-ui/icons/Help';
import theme from "../theme";
import { withOktaAuth } from '@okta/okta-react';

function StartStopButtonText(props) {
  if (props.doorState === 'open') {
    return <span>Close Garage Door</span>
  } else if (props.doorState === 'halfopen') {
    return <span>Open or Close Garage Door</span>
  } else {
    return <span>Open Garage Door</span>
  }
}

function StartStopButtonIcon(props) {
  if (props.doorState === 'open') {
    return <KeyboardArrowDownIcon />
  } else if (props.doorState === 'halfopen') {
    return <HelpIcon />
  } else {
    return <KeyboardArrowUpIcon />
  }
}

function UpdatingGarageDoorText(props) {
  if (props.doorState === 'open') {
    return <span>Closing</span>
  } else if (props.doorState === 'closed') {
    return <span>Opening</span>
  } else {
    return <span>I have no idea what is going on?!?</span>
  }
}

interface IGarageDoorProps {
  authState?: any
  authService?: any
}

interface IGarageDoorState {
  doorState?: string
  updating: boolean
  doorProgress: number
  stop: boolean
  lastKnown?: string
}

class GarageDoor extends React.Component<IGarageDoorProps, IGarageDoorState> {
  client: GaragedoorClient
  constructor(props) {
    super(props);
    this.state = {
      doorState: null,
      updating: false,
      doorProgress: 0,
      stop: false,
      lastKnown: null,
    }
    this.client = new GaragedoorClient(props.authState)
  }

  getGarageDoorState = () => {
    this.client.status()
      .then(res => {
        let lastKnown = this.state.lastKnown
        if (res.current_status !== 'halfopen') {
          lastKnown = res.current_status
        }
        this.setState({doorState: res.current_status, doorProgress: res.open_state, lastKnown: lastKnown});
      })
    setTimeout(this.getGarageDoorState, 5*1000)
  }

  componentDidMount() {
    this.getGarageDoorState()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.stop && !prevState.stop) {
      this.client.toggle()
      this.setState({updating: false, stop: false})
    }
    if (this.state.updating && !prevState.updating) {
     this.client.toggle()
    }
    if (this.state.updating && prevState.doorState !== this.state.doorState && this.state.doorState !== 'halfopen') {
      this.setState({updating: false})
    }
  }

  render() {
    if (this.state.doorState === null) {
      return (
        <Container maxWidth="md">
          <Typography>
            loading...
          </Typography>
        </Container>
      )
    }
    if (this.state.updating) {
      return (
        <div>
          <Container maxWidth="sm">
            <Box my={4}>
              <Typography variant="h3">
                <LinearProgress color="secondary" /><br />
                <UpdatingGarageDoorText doorState={this.state.lastKnown} />
              </Typography>
            </Box>
          </Container>
          <Container maxWidth="sm">
            <Box my={4}>
              <Button
                onClick={() => {
                  let newLastKnown = 'open'
                  if (this.state.lastKnown === 'open') {
                    newLastKnown = 'closed'
                  }
                  this.setState({stop: true, lastKnown: newLastKnown})
                }}
                fullWidth={true}
                variant="contained"
                color="primary"
                startIcon={<PanToolIcon />}>
                Stop Garage Door
              </Button>
            </Box>
          </Container>
        </div>
      )
    }
    return (
      <div>
        <Container maxWidth="md">
          <Typography align={'center'} style={{marginTop: theme.spacing(4)}} component="h3" variant={'h4'}>
            The garage door is
          </Typography>
          <Typography align={'center'} component="h1" variant={'h2'} style={{textTransform: 'capitalize'}}>
            {this.state.doorState}
          </Typography>
        </Container>
        <Container maxWidth="sm">
          <Box my={4}>
            <Button
              onClick={() => {
                let lastKnown = this.state.lastKnown
                if (this.state.doorState !== 'halfopen') {
                  lastKnown = this.state.doorState
                }
                this.setState({updating: true, lastKnown: lastKnown})
              }}
              fullWidth={true}
              variant="contained"
              color="primary"
              startIcon={<StartStopButtonIcon doorState={this.state.lastKnown} />}>
              <StartStopButtonText doorState={this.state.lastKnown} />
            </Button>
          </Box>
        </Container>
      </div>
    );
  }
}

export default withOktaAuth(GarageDoor);
