import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid
} from "@material-ui/core";
import {Link} from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

function Body() {
  const classes = useStyles()
  return (
    <div>
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          PS13 Home Controls
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Control center for some of the things connected.
        </Typography>
      </Container>
    </div>
    <Container className={classes.cardGrid} maxWidth="md">
      <Grid container spacing={4}>
        <Grid item key={1} xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              image="https://source.unsplash.com/994f0ckhths"
              title="Image title"
            />
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h5" component="h2">
                Garage door
              </Typography>
              <Typography>
                Control garage door
              </Typography>
            </CardContent>
            <CardActions>
              <Button component={Link} to={"/garagedoor"} size="small" color="primary">
                Control
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  </div>
  );
}

function Index() {
  return (
    <Body />
  );
}

export default Index;
